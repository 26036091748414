import Vue from 'vue'

import CBtn from '@/components/kit/button/CBtn'
import CTabs from '@/components/kit/tabs/CTabs'
import CIcon from '@/components/kit/icons/CIcon'
import CInput from '@/components/kit/inputs/CInput'
import CSelect from '@/components/kit/inputs/CSelect'
import CPaging from '@/components/kit/paging/CPaging'
import CIconBtn from '@/components/kit/button/CIconBtn'
import CRawTable from '@/components/kit/tables/CRawTable'
import CBaseModal from '@/components/kit/modals/CBaseModal'
import CToggleBtn from '@/components/kit/button/CToggleBtn'
import CMultiSelect from '@/components/kit/inputs/CMultiSelect'
import CListDataSelect from '@/components/kit/inputs/CListDataSelect'

import Divider from '@/components/layout/Divider'
import PageTitle from '@/components/layout/PageTitle'
import PageSubTitle from '@/components/layout/PageSubTitle'

Vue.component('c-btn', CBtn)
Vue.component('c-tabs', CTabs)
Vue.component('c-icon', CIcon)
Vue.component('c-input', CInput)
Vue.component('c-paging', CPaging)
Vue.component('c-select', CSelect)
Vue.component('c-icon-btn', CIconBtn)
Vue.component('c-raw-table', CRawTable)
Vue.component('c-base-modal', CBaseModal)
Vue.component('c-toggle-btn', CToggleBtn)
Vue.component('c-multi-select', CMultiSelect)
Vue.component('c-list-data-select', CListDataSelect)

Vue.component('divider', Divider)
Vue.component('page-title', PageTitle)
Vue.component('page-sub-title', PageSubTitle)
