<template>
  <div class="flex py-1 overflow-x-auto" v-if="value">
    <button
      v-for="(tab, index) in tabs"
      :key="index"
      class="focus:outline-none border-b-4 font-bold text-gray-700 py-2 pl-4"
      :class="value.key === tab.key ? 'border-green-600' : ''"
      @click="onChange(tab)"
    >
      {{ tab.title }}
    </button>
  </div>
</template>

<script>
export default {
  name: 'CTabs',
  props: {
    value: {
      type: Object,
      required: true,
    },
    tabs: {
      type: Array,
      required: true,
    },
  },
  methods: {
    onChange(tab) {
      this.$emit('input', tab)
      this.$emit('onChange', tab)
    },
  },
}
</script>
