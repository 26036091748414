<template>
  <div class="w-auto">
    <div class="overflow-y-auto" :style="height ? `max-height: ${height}` : ''">
      <table class="w-full">
        <thead>
          <slot name="header" v-bind:headers="headers" />
        </thead>

        <tbody>
          <tr
            v-for="(item, index) in items"
            :key="index"
            @click="_itemClick(item)"
            :class="itemClass"
          >
            <slot name="item" v-bind:item="item" v-bind:index="index" />
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CRawTable',
  props: {
    headers: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    height: {
      type: [String, Number],
      required: false,
    },
    itemClick: {
      type: Function,
      required: false,
    },
    itemClass: {
      type: String,
      default: '',
    },
  },

  methods: {
    _itemClick(item) {
      if (this.itemClick) {
        this.itemClick(item)
      }
    },
  },
}
</script>
