import Vue from 'vue'
import Axios from 'axios'
import { parse } from 'yaml'
import VueAxios from 'vue-axios'

const ApiService = {
  init() {
    Vue.use(VueAxios, Axios)
    Vue.axios.defaults.baseURL = '/'

    Vue.axios.interceptors.response.use((response) => {
      if (typeof response.data === 'string') {
        return parse(response.data)
      }
      return response.data.result || response.data
    }, (error) => {
      if (error.response.status === 403) {
        window.location.href = '/forbidden'
      }
      return Promise.reject(error)
    })
  },
};

export default ApiService;
