const eps = 1e-6
const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]

function _2digit(val) {
  return val > 9 ? '' + val : '0' + val
}

function _fix2(val) {
  return _fix(val, 2)
}

function _fix4(val) {
  return _fix(val, 4)
}

function _fix(val, k) {
  if (typeof val !== 'number') {
    return val
  }
  const digit = 10**k
  return Math.round(val * digit) / digit
}

function _formatNumber(val) {
  if (val === null || val === undefined) {
    return '-'
  }

  if (typeof val !== 'number') {
    return val
  }
  if (Math.abs(val) < eps) {
    return '-'
  }
  const arr = val.toString().split('.')
  return (
    arr[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',') + (arr[1] ? '.' + arr[1].slice(0, 3) : '')
  )
}

const numberFilterMixin = {
  filters: {
    fix2(val) {
      return _fix2(val)
    },
    fix4(val) {
      return _fix4(val)
    },
    fix8(val) {
      return _fix(val, 8)
    },
    noDecimal(val) {
      return typeof val !== 'number' ? val : Math.round(val)
    },
    formatNumber(val) {
      return _formatNumber(val)
    },
    bigNumber(val) {
      if (typeof val !== 'number') {
        return val
      }

      if (Math.abs(val) < eps) {
        return '-'
      }

      if (val > 1e9) {
        return _formatNumber(_fix2(val / 1e9)) + 'B'
      }
      if (val > 1e6) {
        return _formatNumber(_fix2(val / 1e6)) + 'M'
      }
      if (val > 1e3) {
        return _formatNumber(_fix2(val / 1e3)) + 'K'
      }

      return val
    },
    percentage2(val) {
      if (typeof val !== 'number') {
        return val
      }
      if (Math.abs(val) < eps) {
        return '0%'
      }
      return _fix2(val * 100) + '%'
    },
    percentage3(val) {
      if (typeof val !== 'number') {
        return val
      }
      if (Math.abs(val) < eps) {
        return '0%'
      }
      return (Math.round(val * 1e5) / 1e3) + '%'
    },
    percentage4(val) {
      if (typeof val !== 'number') {
        return val
      }
      if (Math.abs(val) < eps) {
        return '0%'
      }
      return _fix4(val * 100) + '%'
    },
  },
}

const dateFilterMixin = {
  filters: {
    shortDate(val) {
      if (!val) {
        return ''
      }
      const date = new Date(val)
      const month = months[date.getMonth()]
      const day = date.getDate()
      const hour = _2digit(date.getHours())
      const min = _2digit(date.getMinutes())
      return `${month}-${day} ${hour}:${min}`
    },
    formatDate(val) {
      if (!val) {
        return ''
      }
      const date = new Date(val)
      const year = date.getFullYear()
      const month = date.getMonth() + 1
      const day = date.getDate()
      const hour = _2digit(date.getHours())
      const min = _2digit(date.getMinutes())
      return `${year}/${month}/${day} ${hour}:${min}`
    },
    date(val) {
      const date = new Date(val)
      const month = months[date.getMonth()]
      const day = _2digit(date.getDate())
      const hour = _2digit(date.getHours())
      const min = _2digit(date.getMinutes())
      return `${month}. ${day} ${hour}:${min}`
    },
    fullDate(val) {
      const date = new Date(val)
      const year = date.getFullYear()
      const month = _2digit(date.getMonth() + 1)
      const day = _2digit(date.getDate())
      const hour = _2digit(date.getHours())
      const min = _2digit(date.getMinutes())
      return `${year}-${month}-${day} ${hour}:${min}`
    },
  },
}

module.exports = {
  dateFilterMixin,
  numberFilterMixin,
}
