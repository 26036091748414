import Highcharts from 'highcharts'
import drilldownInit from 'highcharts/modules/drilldown'
import stockInit from 'highcharts/modules/stock'
import highchartsMore from 'highcharts/highcharts-more'

drilldownInit(Highcharts)
stockInit(Highcharts)
highchartsMore(Highcharts)

const timezone = new Date().getTimezoneOffset()
Highcharts.setOptions({
  lang: {
    drillUpText: '<< Return {series.name}',
    thousandsSep: ',',
  },
  global: {
    timezoneOffset: timezone,
  },
})
