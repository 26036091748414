<template>
  <button @click="$emit('click', $event)" class="focus:outline-none">
    <c-icon :name="name" :width="width" :height="height" />
  </button>
</template>

<script>
import CIcon from '@/components/kit/icons/CIcon'

export default {
  name: 'CIconBtn',
  props: {
    name: {
      type: String,
      required: true,
    },
    width: {
      type: [Number, String],
      default: 32,
    },
    height: {
      type: [Number, String],
      default: 32,
    },
  },
  components: { CIcon },
}
</script>
