<template>
  <div @mouseover="hover = true" @mouseleave="hover = false">
    <button
      class="flex justify-center w-10 h-10 content-center flex-wrap border rounded bg-gray-300 border-none hover:bg-gray-400 focus:outline-none"
      @click="onClick"
      :class="
        $route.path.startsWith(`/${to}`) || $route.path.startsWith(`${to}`)
          ? 'bg-gray-400'
          : ''
      "
    >
      <slot></slot>
    </button>

    <slot name="tooltip">
      <div
        v-if="tooltip && hover"
        class="absolute z-50"
        style="margin-top: -33px; margin-left: 50px"
      >
        <div
          class="bg-black text-white text-xs rounded py-1 px-4 right-0 bottom-full"
        >
          {{ tooltip }}
        </div>
      </div>
    </slot>
  </div>
</template>

<script>
export default {
  name: 'NavBtn',
  props: {
    tooltip: {
      type: String,
    },
    to: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      hover: false,
    }
  },
  methods: {
    onClick(event) {
      if (this.to) {
        this.$router.push(this.to)
      }
      this.$emit('click', event)
    },
  },
}
</script>
