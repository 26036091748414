<template>
  <label class="block mt-2">
    <span v-if="label" class="font-bold">{{ label }}</span>
    <input
      class="input-box text-md focus:border-blue-500"
      :class="classes"
      :disabled="disabled"
      :value="value"
      :type="type"
      @input="onChange"
      :placeholder="placeholder"
    />
  </label>
</template>

<script>
export default {
  name: 'CInput',
  props: {
    label: {
      type: String,
      default: '',
    },
    placeholder: String,
    value: {
      type: [String, Number],
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      required: false,
      default: 'text',
    },
    textRight: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onChange($event) {
      this.$emit('input', $event.target.value)
      this.$emit('onChange', $event.target.value)
    },
  },
  computed: {
    classes() {
      let classes = []
      if (this.disabled) {
        classes.push('cursor-not-allowed')
      }

      if (this.textRight) {
        classes.push('text-right')
      }

      return classes.join(' ')
    },
  },
}
</script>

<style lang="css" scoped>
.input-box {
  @apply block;
  @apply w-full;
  @apply py-1;
  @apply px-2;
  @apply rounded-md;
  @apply border;
  @apply border-gray-400;
  @apply outline-none;
  @apply shadow-inner;
}
</style>
