<template>
  <div class="font-black text-lg mb-1 items-center">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'PageTitle',
}
</script>
