<template>
  <label class="block mt-2">
    <span class="font-bold">{{ label }}</span>
    <select v-model="selected" class="selector" :disabled="disabled">
      <option
        v-for="(option, index) in options"
        v-bind:value="{ key: option.key, text: option.text }"
        :key="index"
      >
        {{ option.text }}
      </option>
    </select>
  </label>
</template>

<script>
export default {
  name: 'CSelect',
  props: {
    value: {
      required: false,
    },
    label: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selected: {},
    }
  },
  created() {
    this.selected = this.options.find((el) => el.key === this.value)
  },
  watch: {
    selected(option) {
      if (option) {
        this.$emit('input', option.key)
      }
    },
  },
}
</script>

<style lang="css" scoped>
.selector {
  @apply w-full;
  @apply py-1;
  @apply px-2;
  @apply rounded-md;
  @apply outline-none;
  @apply shadow-inner;
  @apply border;
  @apply border-gray-400;
  @apply bg-white;
}
</style>
