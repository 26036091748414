import { MUTATION_VOLUME } from '@/store/mutations.types'

export const VOLUME = 'VOLUME'
const state = {
  muteNotification: false,
  volume: 1, // default
}

const getters = {
  getMuteNotification (state) {
    return state.muteNotification
  },
  getVolume(state) {
    return state.volume
  },
}

const mutations = {
  [MUTATION_VOLUME](state, volume) {
    const value = parseInt(volume * 10) / 10
    state.volume = value
    localStorage.setItem(VOLUME, value)
  },
}

export default {
  state,
  getters,
  mutations,
}

