<template>
  <svg
    @click="$emit('click', $event)"
    xmlns="http://www.w3.org/2000/svg"
    class="icon icon-tabler icon-tabler-archive"
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    :stroke-width="strokeWidth"
    :stroke="color"
    fill="none"
    stroke-linecap="round"
    stroke-linejoin="round"
    v-html="icons[name]"
  />
</template>

<script>
const icons = require('./tablericons.json')

export default {
  name: 'CIcon',
  props: {
    name: {
      type: String,
      required: true,
    },
    width: {
      type: [Number, String],
      default: 32,
    },
    height: {
      type: [Number, String],
      default: 32,
    },
    strokeWidth: {
      type: [Number, String],
      default: 1.5,
    },
    color: {
      type: String,
      default: '#009688',
    },
  },
  data() {
    return {
      icons,
    }
  },
}
</script>
