import Vue from 'vue'
import Notifications from 'vue-notification'

import App from './App.vue'
import store from './store'
import router from '@/router'
import './registerComponents'
import './plugins/derective'
import './plugins/highcharts'
import '@/assets/css/tailwind.css'
import ApiService from '@/services/http'
import { dateFilterMixin, numberFilterMixin } from '@/mixins/filters'

Vue.config.productionTip = false

ApiService.init()
Vue.mixin(dateFilterMixin)
Vue.mixin(numberFilterMixin)
Vue.use(Notifications)
const regex = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i
Vue.prototype.$isMobile = regex.test(navigator.userAgent)

new Vue({
  render: (h) => h(App),
  router,
  store,
}).$mount('#app')

global.ONE_SECOND = 1e3
global.ONE_MINUTE = 60 * global.ONE_SECOND
global.ONE_HOUR = 60 * global.ONE_MINUTE
global.ONE_DAY = 24 * global.ONE_HOUR
