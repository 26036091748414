<template>
  <div
    class="fixed w-full h-full top-0 left-0 flex items-center justify-center z-20"
  >
    <div
      class="modal-overlay absolute w-full h-full bg-gray-600 opacity-50"
      @click="outSide"
    />
    <slot />
  </div>
</template>

<script>
export default {
  name: 'CBaseModal',
  created() {
    document.addEventListener('keydown', this.onEsc)
  },
  methods: {
    outSide() {
      this.$emit('clickAway', true)
    },
    onEsc(event) {
      if (event.key === 'Escape') {
        this.$emit('onEsc')
      }
    },
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.onEsc)
  },
}
</script>
