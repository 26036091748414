import Vue from 'vue'
import Vuex from 'vuex'

import ui from './modules/ui.module'
import system from './modules/system.module'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    ui,
    system,
  },
})

export default store
