import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);
const routes = [
  {
    path: '/',
    redirect: 'markets',
  },
  {
    path: '/markets',
    component: () => import('@/views/Markets/index.vue'),
  },
  {
    path: '/orders',
    component: () => import('@/views/Orders/index.vue'),
  },
  {
    path: '/configs',
    component: () => import('@/views/Configs/index.vue'),
  },
  {
    path: '/rss',
    component: () => import('@/views/Rss/index.vue'),
  },
  {
    path: '/groups',
    component: () => import('@/views/Accounts/index.vue'),
    children: [
      {
        path: '',
        component: () => import('@/views/Accounts/groups.vue'),
      },
    ],
  },
  {
    path: '/forbidden',
    component: () => import('@/views/403/index.vue'),
  },
  {
    path: '*',
    component: () => import('@/views/404/index.vue'),
  },
]

if (process.env.NODE_ENV === 'local') {
  routes.push({
    path: '/demo',
    component: () => import('@/views/Demo/index.vue'),
  })
}

const router = new VueRouter({
  mode: 'history',
  routes,
});


export default router;
