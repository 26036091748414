<template>
  <div>
    <label :for="toggleKey">
      <slot name="label">
        <span class="text-xs text-gray-700">{{ label }}</span>
      </slot>
    </label>
    <div
      class="relative inline-block w-10 ml-2 align-middle select-none transition duration-200 ease-in"
    >
      <input
        type="checkbox"
        :name="toggleKey"
        :id="toggleKey"
        class="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none outline-none cursor-pointer"
        :value="value"
        @input="onChange"
        ref="tt"
      />
      <label
        :for="toggleKey"
        class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'CToggleBtn',
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    label: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      toggleKey: '',
    }
  },
  created() {
    const random = Math.random()
    this.toggleKey = `toggle-key-${btoa(random).substr(3, 9)}`
  },
  mounted() {
    this.$refs.tt.checked = this.value
  },
  methods: {
    onChange(event) {
      this.$emit('onChange', event.target.checked) // for custom
      this.$emit('input', event.target.checked) // for two binding
    },
  },
}
</script>

<style scoped>
.toggle-checkbox:checked {
  @apply: right-0 border-green-400;
  right: 0;
  border-color: #68d391;
}

.toggle-checkbox:checked + .toggle-label {
  @apply: bg-green-400;
  background-color: #68d391;
}
</style>
