<template>
  <div>
    <div class="select-wrapper">
      <input
        class="focus:outline-none w-full"
        :placeholder="placeholder"
        v-model="inputSearch"
        :class="isHasSelected ? 'hidden' : ''"
        @click="showOrHideOptions"
      />
      <div
        v-for="option in selectedOptions"
        :key="option.key"
        class="option-item"
      >
        <div class="overflow-x-auto truncate">{{ option.text }}</div>
        <c-icon-btn
          name="x"
          height="20"
          width="20"
          @click="selectOption(option)"
        />
      </div>
      <c-icon-btn
        class="ml-auto"
        name="circle-x"
        height="40"
        width="30"
        @click="clearAll()"
        v-if="selectedOptions.length"
      />
      <c-icon-btn
        :name="showOptions ? 'chevron-up' : 'chevron-down'"
        color="black"
        class="ml-1"
        @click="showOrHideOptions"
      />
    </div>
    <div
      v-if="showOptions"
      class="shower-options"
      style="height: 400px"
      v-click-outside="clickOutSideOptions"
    >
      <div
        v-for="option in getOptions"
        :key="option.key"
        class="hover:bg-gray-300 w-full flex"
        @click="selectOption(option)"
      >
        <c-icon
          name="checkbox"
          v-if="isSelectedOption(option)"
          height="20"
          width="20"
        />
        <div class="mx-2">
          {{ option.text }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CMultiSelect',
  props: {
    selected: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Array,
      required: true,
    },
    placeholder: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showOptions: false,
      selectedOptions: [],
      inputSearch: '',
    }
  },
  methods: {
    clickOutSideOptions() {
      this.showOptions = false
    },
    showOrHideOptions(event) {
      event.stopPropagation()
      this.showOptions = !this.showOptions
    },
    selectOption(option) {
      let indexI = -1
      const isSelected = this.selectedOptions.find((_option, index) => {
        indexI = index
        return _option.key === option.key
      })
      if (isSelected) {
        this.selectedOptions.splice(indexI, 1)
      } else {
        this.selectedOptions.push(option)
      }
      this.emitOnChange()
    },
    emitOnChange() {
      this.$emit('onChange', this.selectedOptions)
    },
    clearAll() {
      this.selectedOptions = []
      this.emitOnChange()
    },
    isSelectedOption(option) {
      return this.selectedOptions.find((_option) => _option.key === option.key)
    },
  },
  computed: {
    isHasSelected() {
      return this.selectedOptions.length > 0
    },
    getOptions() {
      if (this.inputSearch) {
        return this.options.filter((_option) =>
          _option.text.includes(this.inputSearch)
        )
      }
      return this.options
    },
  },
  watch: {
    selected(val) {
      this.selectedOptions = val
    },
    inputSearch() {
      this.$emit('onTextChange', this.inputSearch)
    },
  },
}
</script>

<style scoped>
.select-wrapper {
  @apply flex;
  @apply border;
  @apply border-2;
  @apply rounded;
  @apply px-1;
}

.option-item {
  @apply flex;
  @apply my-auto;
  @apply border-2;
  @apply border;
  @apply rounded;
  @apply mr-1;
  @apply px-1;
  @apply items-center;
  @apply overflow-hidden;
}

.shower-options {
  @apply absolute;
  @apply border-2;
  @apply border;
  @apply rounded;
  @apply cursor-default;
  @apply z-50;
  @apply bg-white;
  @apply overflow-y-scroll;
}
</style>
