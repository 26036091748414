<template>
  <label class="block">
    <span class="font-bold">{{ label }}</span>
    <div class="flex w-full">
      <input
        :list="listName"
        :name="listName"
        :placeholder="placeholder"
        @change="onChange"
        ref="data"
        class="w-full py-1 px-2 text-md rounded-md outline-none shadow-inner border border-gray-400 focus:border-blue-500 bg-white"
      />
      <c-icon-btn name="x" @click="clearInput" />
    </div>
    <datalist :id="listName">
      <option
        v-for="(option, index) in options"
        :value="option.text"
        :key="index"
      >
        {{ option.text }}
      </option>
    </datalist>
  </label>
</template>

<script>
import CIconBtn from '@/components/kit/button/CIconBtn'

export default {
  name: 'CListDataSelect',
  components: { CIconBtn },
  props: {
    value: {
      required: false,
    },
    label: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    placeholder: String,
  },
  data() {
    return {
      selected: {},
      listName: '',
    }
  },
  created() {
    this.selected = this.options.find((el) => el.key === this.value)
    this.listName = `data-list-select-${btoa(Math.random()).substr(3, 9)}`
  },
  methods: {
    onChange($event) {
      this.$emit('onSelect', $event.target.value)
    },
    clearInput() {
      // TODO(nakhoa): refactor this method
      this.$refs.data.value = ''
      this.$emit('onSelect', { value: '' })
    },
  },
}
</script>
