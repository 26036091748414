// UI module
const MUTATION_UI_APP_WIDTH = 'MUTATION_UI_APP_WIDTH'

// System module
const MUTATION_VOICE_NOTIFICATION = 'MUTATION_VOICE_NOTIFICATION'
const MUTATION_VOLUME = 'MUTATION_VOLUME'

module.exports = {
  // ui module
  MUTATION_UI_APP_WIDTH,

  // system module
  MUTATION_VOICE_NOTIFICATION,
  MUTATION_VOLUME,
}
