<template>
  <div class="font-bold text-md mb-1">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'PageSubTitle',
}
</script>
