<template>
  <button
    @click="onClick($event)"
    class="bg-gray-400 py-1 px-3 rounded-md shadow-md focus:outline-none h-8"
    :class="disabled ? 'cursor-not-allowed opacity-50' : ''"
    :disabled="disabled"
  >
    <slot>{{ label }}</slot>
  </button>
</template>

<script>
export default {
  name: 'CBtn',
  props: {
    disabled: {
      default: false,
    },
    label: {
      default: '',
    },
  },
  methods: {
    onClick(event) {
      this.$emit('click', event)
    },
  },
}
</script>
