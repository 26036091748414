import { MUTATION_UI_APP_WIDTH } from '@/store/mutations.types'
const MOBILE_BREAK_POINT = 400

const state = {
  appWidth: 0,
}

const getters = {
  getAppWidth(state) {
    return state.appWidth
  },
  isMobile() {
    return state.appWidth < MOBILE_BREAK_POINT
  },
}

const mutations = {
  [MUTATION_UI_APP_WIDTH](state, newWidth) {
    state.appWidth = newWidth
  },
}

export default {
  state,
  getters,
  mutations,
}
