<template>
  <nav class="bg-white shadow mx-auto flex justify-between items-center">
    <div class="flex">
      <router-link
        to="/"
        class="font-extrabold m-3 uppercase inline-flex hover:text-pink-700 transition-all duration-500"
      >
        <c-icon name="currency-bitcoin" />
        <div class="flex flex-wrap content-center pt-1 font-extrabold">
          Trading Tool
        </div>
      </router-link>
    </div>
  </nav>
</template>

<script>
import CIcon from '@/components/kit/icons/CIcon'
export default {
  name: 'AppBar',
  components: { CIcon },
}
</script>
