function formatNumber(value) {
  const arr = value.toString().split('.');
  return (
    Number(arr[0]).toLocaleString() + (arr[1] ? '.' + arr[1] : '')
  );
}

function _2digit(val) {
  return val < 10 ? `0${val}` : val
}

function formatDate(val) {
  if (!val) {
    return '';
  }
  const d = new Date(val)
  const year = d.getFullYear()
  const month = _2digit(d.getMonth() + 1)
  const date = _2digit(d.getDate())
  let hour = _2digit(d.getHours())
  let minute = _2digit(d.getMinutes())
  return `${year}/${month}/${date} ${hour}:${minute}`
}

module.exports = {
  formatDate,
  formatNumber,
}
