<template>
  <div
    class="fixed left-0 bottom-0 flex-row flex w-full md:w-auto justify-center md:justify-start md:space-x-0 space-x-10 md:relative md:flex-wrap md:flex-col bg-gray-300 md:p-1"
  >
    <nav-btn
      v-for="(item, index) in navigations"
      :key="index"
      class="mt-0 md:mt-2"
      :tooltip="item.tooltip"
      :to="item.to"
    >
      <c-icon :name="item.icon" />
    </nav-btn>

    <nav-btn class="mt-0 md:mt-2" @click="showVolume = true">
      <c-icon name="adjustments-horizontal" />

      <template v-slot:tooltip>
        <div
          v-if="showVolume"
          v-on-clickaway="onClickaway"
          class="bg-gray-100 rounded flex items-center p-3 absolute z-50 shadow ml-10 w-64"
          style="margin-top: -85px"
        >
          <div>
            <div class="font-bold">Volume</div>
            <div class="flex">
              <c-icon :name="volume === '0' ? 'volume-3' : 'volume'" />
              <input type="range" min="0" max="1" step="0.1" v-model="volume" />
            </div>
            <div class="pt-2">
              <c-input text-right label="Liquidation" v-model="liquid" />
              <c-input text-right label="Mint Limit" v-model="mint" />
              <c-btn class="h-8 self-end mt-2" @click="saveConfig">Save</c-btn>
            </div>
          </div>
        </div>
      </template>
    </nav-btn>
  </div>
</template>

<script>
import NavBtn from '@/components/layout/NavBtn'
import CIcon from '@/components/kit/icons/CIcon'
import { mapGetters } from 'vuex'
import { MUTATION_VOLUME } from '@/store/mutations.types'
import { directive as onClickaway } from 'vue-clickaway'
import { formatNumber } from '@/utils/number'

export default {
  name: 'NavBar',
  components: { CIcon, NavBtn },
  directives: { onClickaway },
  data() {
    return {
      showVolume: false,
      navigations: [
        {
          icon: 'activity',
          tooltip: 'Market',
          to: 'markets',
        },
        {
          icon: 'coin',
          tooltip: 'Orders',
          to: 'orders',
        },
        {
          icon: 'settings',
          tooltip: 'Configs',
          to: 'configs',
        },
        {
          icon: 'users',
          tooltip: 'Groups',
          to: 'groups',
        },
        {
          icon: 'rss',
          tooltip: 'RSS',
          to: 'rss',
        },
      ],
      mintLimit: 0,
      liquidation: 0,
    }
  },
  async created() {
    const data = await this.$http.get('/api/v1/markets/app-config')
    this.liquidation = formatNumber(data.liquidation)
    this.mintLimit = formatNumber(data.mintLimit)
  },
  methods: {
    onClickaway() {
      if (this.showVolume) {
        this.showVolume = false
      }
    },
    async saveConfig() {
      await this.$http.post('/api/v1/markets/app-config', {
        liquidation: parseInt(this.liquidation.replaceAll(',', '')),
        mintLimit: parseInt(this.mintLimit.replaceAll(',', '')),
      })
      this.$notify({
        group: 'notify',
        title: 'Notifications',
        text: '更新しました。',
      })
    },
  },
  computed: {
    liquid: {
      get() {
        return this.liquidation
      },
      set(value) {
        value = value.replaceAll(',', '')
        this.liquidation = formatNumber(value)
      },
    },
    mint: {
      get() {
        return this.mintLimit
      },
      set(value) {
        value = value.replaceAll(',', '')
        this.mintLimit = formatNumber(value)
      },
    },
    ...mapGetters({ volume: 'getVolume' }),
    volume: {
      get() {
        return this.$store.state.system.volume.toString()
      },
      set(val) {
        this.$store.commit(MUTATION_VOLUME, parseFloat(val))
      },
    },
  },
}
</script>
