<template>
  <div class="w-full pt-4 flex justify-center">
    <nav>
      <ul class="flex pl-0 rounded list-none">
        <li v-if="showStartBtn()" @click="onClickPage(1)">
          <div class="paging-item">
            <c-icon name="chevrons-left"></c-icon>
          </div>
        </li>

        <li v-if="showPrevBtn()" @click="onClickPage(value - 1)">
          <div class="paging-item">
            <c-icon name="chevron-left"></c-icon>
          </div>
        </li>

        <li class="mx-1 md:mx-2" />

        <li
          v-for="(pg, index) in getPageSequence()"
          :key="index"
          @click="onClickPage(pg)"
        >
          <div :class="pg === value ? 'bg-green-500' : ''" class="paging-item">
            {{ pg }}
          </div>
        </li>

        <li class="mx-1 md:mx-2" />

        <li v-if="showNextBtn()" @click="onClickPage(value + 1)">
          <div class="paging-item">
            <c-icon name="chevron-right" />
          </div>
        </li>

        <li v-if="showEndBtn()" @click="onClickPage(totalPage)">
          <div class="paging-item">
            <c-icon name="chevrons-right" />
          </div>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  name: 'CPaging',
  props: {
    value: {
      type: Number,
      required: true,
    },
    totalPage: {
      type: Number,
      required: true,
    },
  },
  methods: {
    showStartBtn() {
      return this.totalPage - (this.totalPage - this.value) > 2
    },
    showPrevBtn() {
      return this.totalPage - (this.totalPage - this.value) > 2
    },
    showEndBtn() {
      return this.value + 2 < this.totalPage
    },
    showNextBtn() {
      return this.value + 2 < this.totalPage
    },
    getPageSequence() {
      if (this.totalPage === 1) {
        return [1]
      }

      let from = this.value - 2
      let addMore = 0
      if ([1, 2].includes(this.value)) {
        from = 1
        if (this.value === 1) {
          addMore = 2
        }
        if (this.value === 2) {
          addMore = 1
        }
      }

      let to = this.value + 2 + addMore
      if (to >= this.totalPage) {
        to = this.totalPage
      }

      let result = []
      for (let i = from; i <= to; i++) {
        result.push(i)
      }
      return result
    },
    onClickPage(pageNum) {
      if (pageNum === this.value) {
        return
      }

      this.$emit('input', pageNum)
    },
  },
}
</script>

<style scoped lang="postcss">
.paging-item {
  @apply text-xs;
  @apply font-semibold;
  @apply flex;
  @apply w-8;
  @apply h-8;
  @apply mx-1;
  @apply p-0;
  @apply rounded-full;
  @apply items-center;
  @apply justify-center;
  @apply leading-tight;
  @apply relative;
  @apply border;
  @apply border-solid;
  @apply border-green-500;
  @apply cursor-pointer;
}
</style>
