<template>
  <div
    id="app"
    class="w-full text-xs md:text-base font-roboto h-screen"
    ref="app"
  >
    <app-bar style="height: 6%" />
    <div class="mb-10 md:mb-0 flex w-full overflow-x-auto" style="height: 94%">
      <nav-bar class="z-10" />
      <div class="w-full px-4 py-2 overflow-y-auto mb-12 md:mb-0">
        <router-view
          :priceNotificationSpan="priceNotificationSpan"
          @on-update-price-notification-span="
            (value) => (priceNotificationSpan = value)
          "
        />
      </div>
    </div>
    <notifications group="notify" position="bottom right" />
  </div>
</template>

<script>
import AppBar from '@/components/layout/AppBar'
import NavBar from '@/components/layout/NavBar'
import { MUTATION_VOLUME, MUTATION_UI_APP_WIDTH } from '@/store/mutations.types'
import { VOLUME } from '@/store/modules/system.module'
import soundMp3 from './assets/sounds/noti-sound.mp3'
const BUZZ = new Audio(soundMp3)
const FIVE_SECONDS = 5e3

function chunkSubstr(str, size) {
  const numChunks = Math.ceil(str.length / size)
  const chunks = new Array(numChunks)

  for (let i = 0, o = 0; i < numChunks; ++i, o += size) {
    chunks[i] = str.substr(o, size)
  }

  return chunks
}

export default {
  name: 'App',
  components: {
    NavBar,
    AppBar,
  },
  data: () => ({
    nextPriceNotifiction: 0,
    priceNotificationSpan: 60,
    voiceData: [],
    timer: null,
    nextDebug: 0,
    span: 60e3,
    windowId: btoa(Math.random()).substr(3, 9),
    lastVoiceId: Date.now(),
    interval: null,
    clicked: false,
  }),
  computed: {
    volume: {
      get() {
        return this.$store.state.system.volume
      },
    },
  },
  methods: {
    setAppWidth() {
      this.$store.commit(MUTATION_UI_APP_WIDTH, this.$refs.app.clientWidth)
    },
    lock() {
      const now = Date.now()
      const [id, timestamp] = (localStorage.getItem('WINDOW_ID') || '').split(
        '___'
      )

      // Over-ride
      if (!id || this.windowId === id || timestamp < now) {
        const log = this.windowId + '___' + (now + 20e3)
        localStorage.setItem('WINDOW_ID', log)
      }
    },
    getWindowId() {
      const now = Date.now()
      const raw = localStorage.getItem('WINDOW_ID') || ''
      const [id, timestamp] = raw.split('___')

      // Within 10s
      if (timestamp > now + 2 * FIVE_SECONDS) {
        return id
      }

      // Refresh
      if (id === this.windowId) {
        this.lock()
        return id
      }

      // Still valid
      if (timestamp > now) {
        return id
      }

      // Replace
      this.lock()
      return ''
    },
    async speechMessage(msgText, force = false, sound = false) {
      this.$notify({
        group: 'notify',
        title: 'Notifications',
        text: msgText,
      })

      if (sound) {
        await BUZZ.play()
      }

      if (this.volume || force) {
        // -112 --> マイナス112
        if (/-\d+/g.test(msgText)) {
          msgText = msgText.replace(/(-)(\d+)/g, 'マイナス$2')
        }

        chunkSubstr(msgText, 80).forEach((msgText) => {
          const msg = new SpeechSynthesisUtterance()
          msg.lang = 'ja'
          msg.rate = 1.2
          msg.volume = this.volume || 0.2
          msg.text = msgText
          speechSynthesis.speak(msg)
        })
      }
    },
    sayHello() {
      this.speechMessage('おはようございます') // hello message
      this.clicked = true
      this.lock()
      this.next()
    },
    next(span = FIVE_SECONDS) {
      this.timer = setTimeout(this.checkNotify, span)
    },
    async checkNotify() {
      if (this.getWindowId() !== this.windowId) {
        return this.next(2 * FIVE_SECONDS)
      }

      const now = Date.now()
      if (this.nextPriceNotifiction < now) {
        const { price } = await this.$http.get('/api/market/price')
        if (price) {
          this.nextPriceNotifiction = this.priceNotificationSpan * 1e3 + now
          this.speechMessage('価格: ' + price, true, false)
        }
      }

      let data = await this.$http.get('/api/v1/markets/notifition')

      if (!data || !data.length) {
        return this.next()
      }
      data.sort((a, b) => a.timestamp - b.timestamp)
      data = data.filter(({ debug, timestamp }) => {
        if (debug) {
          return true
        }

        if (timestamp <= this.lastVoiceId) {
          return false
        }
        this.lastVoiceId = Math.max(timestamp - 1, this.lastVoiceId)

        if (now - timestamp > 20e3) {
          return false
        }
        return true
      })
      this.lastVoiceId = this.lastVoiceId + 2
      data.forEach(({ content, force, sound }) => {
        this.speechMessage(content, force, sound)
      })
      this.next()
    },
  },
  created() {
    if (process.env.NODE_ENV === 'local') {
      localStorage.setItem('WINDOW_ID', '')
    }
    let volume = localStorage.getItem(VOLUME) || 0 // default is mute
    this.$store.commit(MUTATION_VOLUME, volume)
  },
  mounted() {
    this.timer = setInterval(() => {
      speechSynthesis.cancel()
    }, 600e3)
    document.addEventListener('click', this.sayHello)
    this.setAppWidth()
    window.addEventListener('resize', this.setAppWidth)
  },
  beforeDestroy() {
    clearTimeout(this.timer)
    window.removeEventListener('resize', this.setAppWidth)
  },
  watch: {
    clicked() {
      if (this.clicked) {
        document.removeEventListener('click', this.sayHello)
      }
    },
  },
}
</script>
